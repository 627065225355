<template>
  <div class="flex flex-wrap pt-44 wrapper">
    <div class="w-1/6"></div>
    <div class="w-7/12 px-4 pb-32">

    </div>
    <div class="w-1/5 px-4">
      <ul class="mt-44 border-black border-t">
        <ViewBestPractices />
      </ul>
    </div>
  </div>
</template>

<script>
  import ViewBestPractices from '@/components/ViewBestPractices'

  export default {
    name: 'Discovery-Prosess-Step3',
    components: {
      ViewBestPractices
    },
  }
</script>

<style lang="scss" scoped>
.wrapper{
  height: calc(100% + 2.5rem);
}
.discoveryProcessBottom{
  width: calc(100% - 6rem);
  left: 3rem
}
</style>